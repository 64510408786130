<template>
  <div class="table">
    <div class="container">
      <div class="handle-box">

        <el-input v-model="searchValus" placeholder="关键字" class="handle-input mr10"></el-input>
      </div>
      <div class="handle-box">
        <el-button type="primary" icon="search" @click="search">查询</el-button>
        <!--
          <el-button
            type="primary"
            icon="delete"
            class="handle-del mr10"
            @click="handleEdit"
          >编辑</el-button>
          
           <el-button type="danger" class="handle-del mr10" @click="handleDel()"
              >删除</el-button
            >-->
      </div>
      <el-table class="z_default_table" :empty-text="emptyText" :row-style="rowStyle" :key="tableKey"
        v-loading="tableLoading" element-loading-text="拼命加载中" element-loading-background="rgba(0, 0, 0, 0.5)"
        :data="tableData" border style="width: 100%" ref="multipleTable" use-virtual         height="600"
        @selection-change="handleSelectionChange" @select="handleCheckBox" @select-all="handleSelectAll"
        @row-click="handleRowClick">

        <el-table-column align="center" prop="id" label="驿站编号"></el-table-column>
        <el-table-column align="center" prop="stationName" label="驿站名称"></el-table-column>
        <el-table-column align="center" prop="brandCode_ZT" label="中通">
          <template slot-scope="scope">
            <div class="flex-row-center-container">
              <span v-if="scope.row.brandCode_ZT && scope.row.brandCode_ZT['appId'] && scope.row.brandCode_ZT['appUrl']">
                <el-button type="primary" size="mini" round
                  @click="resetRandCodeData('ZT', scope.row.id, scope.row.brandCode_ZT)">重置</el-button>
              </span>
              <span v-else>
                <el-button type="danger" size="mini" round
                  @click="resetRandCodeData('ZT', scope.row.id, '')">待配置</el-button>
              </span>
              <input v-if="scope.row.brandCode_ZT && scope.row.brandCode_ZT['defaultType'] == 1" style="margin-left: 5px;"
                type="checkbox" onclick="this.checked=!this.checked" checked="checked">
              <input v-else style="margin-left: 5px;" @click="resetDefaultType('ZT', scope.row.id)" type="checkbox">
            </div>
          </template>
        </el-table-column>

        <el-table-column align="center" prop="brandCode_JT" label="极兔">
          <template slot-scope="scope">
            <div class="flex-row-center-container">
              <span v-if="scope.row.brandCode_JT && scope.row.brandCode_JT['appId'] && scope.row.brandCode_JT['appUrl']">
                <el-button type="primary" size="mini" round
                  @click="resetRandCodeData('JT', scope.row.id, scope.row.brandCode_JT)">重置</el-button>
              </span>
              <span v-else>
                <el-button type="danger" size="mini" round
                  @click="resetRandCodeData('JT', scope.row.id, '')">待配置</el-button>
              </span>
              <input v-if="scope.row.brandCode_JT && scope.row.brandCode_JT['defaultType'] == 1" style="margin-left: 5px;"
                type="checkbox" onclick="this.checked=!this.checked" checked="checked" />
              <input v-else style="margin-left: 5px;" @click="resetDefaultType('JT', scope.row.id)" type="checkbox">
            </div>
          </template>
        </el-table-column>

        <el-table-column align="center" prop="brandCode_YD" label="韵达">
          <template slot-scope="scope">
            <div class="flex-row-center-container">
              <span v-if="scope.row.brandCode_YD && scope.row.brandCode_YD['appId'] && scope.row.brandCode_YD['appUrl']">
                <el-button type="primary" size="mini" round
                  @click="resetRandCodeData('YD', scope.row.id, scope.row.brandCode_YD)">重置</el-button>
              </span>
              <span v-else>
                <el-button type="danger" size="mini" round
                  @click="resetRandCodeData('YD', scope.row.id, '')">待配置</el-button>
              </span>
              <input v-if="scope.row.brandCode_YD && scope.row.brandCode_YD['defaultType'] == 1" style="margin-left: 5px;"
                type="checkbox" onclick="this.checked=!this.checked" checked="checked">
              <input v-else style="margin-left: 5px;" @click="resetDefaultType('YD', scope.row.id)" type="checkbox">
            </div>
          </template>
        </el-table-column>

        <el-table-column align="center" prop="brandCode_STO" label="申通">
          <template slot-scope="scope">
            <div class="flex-row-center-container">
              <span
                v-if="scope.row.brandCode_STO && scope.row.brandCode_STO['appId'] && scope.row.brandCode_STO['appUrl']">
                <el-button type="primary" size="mini" round
                  @click="resetRandCodeData('STO', scope.row.id, scope.row.brandCode_STO)">重置</el-button>
              </span>
              <span v-else>
                <el-button type="danger" size="mini" round
                  @click="resetRandCodeData('STO', scope.row.id, '')">待配置</el-button>
              </span>
              <input v-if="scope.row.brandCode_STO && scope.row.brandCode_STO['defaultType'] == 1"
                style="margin-left: 5px;" type="checkbox" onclick="this.checked=!this.checked" checked="checked">
              <input v-else style="margin-left: 5px;" @click="resetDefaultType('STO', scope.row.id)" type="checkbox">
            </div>
          </template>
        </el-table-column>
      </el-table>
      <div class="pagination">
        <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="pageIndex"
          :page-sizes="pageSizes" :page-size="pageSize" layout="total, sizes, prev, pager, next, jumper"
          :total="tableTotal"></el-pagination>
      </div>
    </div>

    <el-dialog title="编辑驿站寄件码" :visible.sync="editVisible" width="40%" class="dialog-border-radius">
      <el-form ref="form" :model="form" label-width="120px" :label-position="labelPosition" class="dialog-form">
        <el-form-item label="品牌">
          <span v-if="form.brandCode == 'ZT'">中通寄件码</span>
          <span v-if="form.brandCode == 'JT'">极兔寄件码</span>
          <span v-if="form.brandCode == 'YD'">韵达寄件码</span>
          <span v-if="form.brandCode == 'STO'">申通寄件码</span>
        </el-form-item>
        <el-form-item label="小程序APPID">
          <el-input v-model="form.appId"></el-input>
        </el-form-item>
        <el-form-item label="小程序URL">
          <el-input v-model="form.appUrl"></el-input>
        </el-form-item>

      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="editVisible = false">取 消</el-button>
        <el-button type="primary" @click="saveEdit">确 定</el-button>
      </span>
    </el-dialog>

  </div>
</template>

<script>
import global from "@/common/global";
import { decodeUserInfo, requestParams, currentDate } from "@/common/tools";
export default {
  components: {},
  data() {
    return {
      userInfo: decodeUserInfo(sessionStorage.getItem("userInfo")),
      pageSize: global.pageSize,
      pageSizes: global.pageSizes,
      pageIndex: 1,
      beforeRow: null,
      tableData: [], //表格数据
      tableTotal: 0,
      tableKey: Math.random(),
      searchValus: "",
      multipleSelection: [], //选中的行
      addForm: {
      },
      resetVisible: false,
      delVisible: false,
      editVisible: false,
      form: {
        brandCode: "",
        stationId: "",
        id: "",
        appId: "",
        appUrl: ""
      },
      labelPosition: "right",
      indexArr: [],
      tableLoading: false
    };
  },
  created() {
    this.getData();
  },
  updated() {

  },
  computed: {

  },
  methods: {
    rowStyle({ row, rowIndex }) {
      for (var i in this.indexArr) {
        if (rowIndex == this.indexArr[i].index) {
          return "background:" + global.listColor + ";";
        }
      }
    },
    handleSelectAll(selection) {
      this.$refs.multipleTable.clearSelection();
    },
    handleReset(index, row) {
      console.log(this.multipleSelection);
      var multipleSelection = this.multipleSelection;
      if (multipleSelection.length == 1) {
        var item = multipleSelection[0];
        this.form.userId = item.userId;
        this.resetVisible = true;
      } else if (multipleSelection.length > 1) {
        this.$message({
          showClose: true,
          message: "只能选择一条记录",
          type: "error"
        });
      } else {
        this.$message({
          showClose: true,
          message: "请选择需要操作的记录",
          type: "error"
        });
      }
    }, saveEdit() {
      var data = this.form;
      this.$axios
        .post("/oa/station/updateStationShip", this.$qs.stringify(data))
        .then(res => {
          if (res.data.code == 0) {
            this.editVisible = false;
            //console.log(this.addForm);
            this.$message.success(`修改成功`);
            this.getData();
          } else {
            this.$message.error(res.data.message);
          }
        });
    }, resetDefaultType(brandCode, stationId) {
      var data = {
        brandCode: brandCode,
        stationId: stationId
      };
      this.$axios
        .post("/oa/station/updateStationShipDefaultType", this.$qs.stringify(data))
        .then(res => {
          if (res.data.code == 0) {
            this.$message.success(`修改成功`);
            this.getData();
          } else {
            this.$message.error(res.data.message);
          }
        });

    }, resetRandCodeData(brandCode, stationId, brandCodeData) {
      this.form.brandCode = brandCode;
      this.form.stationId = stationId;
      if (brandCodeData != '') {
        this.form.id = brandCodeData.id;
        this.form.appId = brandCodeData.appId;
        this.form.appUrl = brandCodeData.appUrl;
      } else {
        this.form.id = '';
        this.form.appId = '';
        this.form.appUrl = '';
      }

      this.editVisible = true;

    }, handleEdit(index, row) {
      console.log(this.multipleSelection);
      var multipleSelection = this.multipleSelection;
      if (multipleSelection.length == 1) {
        var item = multipleSelection[0];
        this.form = JSON.parse(JSON.stringify(item));
        this.editVisible = true;
      } else if (multipleSelection.length > 1) {
        this.$message({
          showClose: true,
          message: "只能选择一条记录",
          type: "error"
        });
      } else {
        this.$message({
          showClose: true,
          message: "请选择需要操作的记录",
          type: "error"
        });
      }
    }, handleDel() {
      var multipleSelection = this.multipleSelection;
      if (multipleSelection.length >= 1) {
        this.delVisible = true;
      } else {
        this.$message({
          showClose: true,
          message: "请选择需要操作的记录",
          type: "error"
        });
      }

    }, deleteRow() {
      var indexArr = this.indexArr;
      var idStr = '';
      var data = {};
      if (this.indexArr.length >= 1) {
        for (var i in indexArr) {
          idStr += indexArr[i].id + ',';
        }
        idStr = idStr.substring(0, idStr.length - 1);
        data.ids = idStr;
      }
      data = requestParams(data);
      this.$axios
        .post(
          "/oa/station/deleteStationId",
          this.$qs.stringify(data)
        )
        .then(res => {
          if (res.data.code == 0) {
            this.$message.success("删除");
            this.delVisible = false;
            this.getData();
          } else {
            this.$message.error(res.data.message);
          }
        });

    },

    // 分页导航
    handleCurrentChange(val) {
      this.pageIndex = val;
      this.getData();
    },
    handleSizeChange(val) {
      this.pageSize = val;
      this.getData();
    },
    checkStatusTypeChange() {
      this.pageIndex = 1;
      this.getData();
    },
    getData() {
      this.tableLoading = true;
      var data = {
        pageSize: this.pageSize,
        pageIndex: this.pageIndex,
        searchValus: this.searchValus
      };
      this.emptyText = " ";
      this.$axios
        .post("/oa/station/stationShipList", this.$qs.stringify(data))
        .then(res => {
          console.log(res);
          this.tableData = res.data.data.rows;
          this.tableKey = Math.random();
          this.tableLoading = false;
          this.tableTotal = Number(res.data.data.total);
          this.emptyText = "暂无数据";
        })
        .catch(error => {
          this.emptyText = "暂无数据";
          this.tableLoading = false;
        });
    },
    search() {
      //   alert(this.select_cate);
      this.getData();
    },
    handleSelectionChange(val) {
      if (val.length > 1) return;
      this.multipleSelection = val;
      this.indexArr = [];
      if (this.multipleSelection.length >= 1) {
        for (var i in this.tableData) {
          for (var j in this.multipleSelection) {
            if (this.tableData[i].id == this.multipleSelection[j].id) {
              this.indexArr[j] = {
                index: i,
                id: this.multipleSelection[j].id
              };
            }
          }
        }
      }
    },
    handleCheckBox(selection, row) {
      // console.log(this.beforeRow, row, this.indexArr, this.multipleSelection)
      if (!this.beforeRow) {
        this.beforeRow = row;
      } else {
        if (this.beforeRow != row) {
          this.beforeRow = row;
          this.$refs.multipleTable.clearSelection();
          this.$refs.multipleTable.toggleRowSelection(row);
        } else {
          if (this.indexArr.length > 0) {
            this.$refs.multipleTable.clearSelection();
          } else {
            this.$refs.multipleTable.clearSelection();
            this.$refs.multipleTable.toggleRowSelection(row);
          }
        }
      }
    },
    handleRowClick(row, event, column) {
      if (!this.beforeRow) {
        this.beforeRow = row;
      } else {
        if (this.beforeRow != row) {
          this.beforeRow = row;
          this.$refs.multipleTable.clearSelection();
        }
      }
      this.$refs.multipleTable.toggleRowSelection(row);
    }, createTimeFormatter(row, column, cellValue, index) {
      return currentDate({
        timestamp: cellValue
      });
    },

  }
};
</script>

<style scoped>
.table {
  height: 100%;
}

.table,
.container {
  height: 100%;
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: flex-start;
}

.table .el-table {
  display: flex;
  flex-flow: column nowrap;
  flex: 1;
}

.handle-box {
  margin-bottom: 20px;
}

.handle-select {
  width: 120px;
}

.search_select {
  width: 160px !important;
}

.handle-input {
  width: 160px;
  display: inline-block;
}

.del-dialog-cnt {
  font-size: 16px;
  text-align: center;
}

.select_name {
  display: inline-block;
  font-size: 14px;
}

.select_container {
  display: inline-block;
}

.select_container .el-date-editor.el-input {
  width: 140px;
}

.brandMs p {
  text-align: left;
}
</style>